import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Process = makeShortcode("Process");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "-welcome-to-kernel",
      "style": {
        "position": "relative"
      }
    }}>{`🌱 Welcome To Kernel`}<a parentName="h1" {...{
        "href": "#-welcome-to-kernel",
        "aria-label": " welcome to kernel permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`Kernel is a peer-to-peer learning environment dedicated to the journey towards a better understanding of truth: in our work, in our relationships with others, and in our inner worlds. `}</p>
    <blockquote>
      <p parentName="blockquote">{`💡 Learn freely, work on your craft, have fun, find the others. `}</p>
    </blockquote>
    <p>{`Our question is: `}<strong parentName="p">{`how can we build a better web for the people we care about`}</strong>{`? `}</p>
    <p>{`And one possible question for you is: `}<strong parentName="p">{`how can I participate in a way that honours my gifts, and who I really am?`}</strong>{` `}</p>
    <p>{`Our answer is: seed an educational environment where people `}<strong parentName="p">{`come to trust`}</strong>{`, through first-hand experience, that we can learn together how to create with care. `}</p>
    <h2 {...{
      "id": "what-is-kernel-though",
      "style": {
        "position": "relative"
      }
    }}>{`What is Kernel, though?`}<a parentName="h2" {...{
        "href": "#what-is-kernel-though",
        "aria-label": "what is kernel though permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Kernel is nine weeks of conversation in a "block" of ~150-300 people intended to connect creativity with care. We aim to change the way we think of contributing and the kinds of projects to which we contribute. Kernel is about humility, honesty, gratitude, and intentional service.`}</p>
    <Process mdxType="Process">
      <p>{`We are humble because, through our curiosity, our creativity, our suffering, our joy we have come to know and embody how little any one person can know in isolation.`}</p>
      <p>{`We are honest because it is the most courageous way to live, the straightest path from head to heart, and the means of connecting them permanently.`}</p>
      <p>{`We are grateful because there is no other choice. Any examined life can only ever lead, in the final analysis, to gratitude beyond reason or measure.`}</p>
      <p>{`Our intention is to serve, because it brings us back - again and again - to humility.`}</p>
      <p>{`We are patient, because planting a seed is not enough: the kernel will only sacrifice itself and become a forest in the right season.`}</p>
    </Process>
    <h2 {...{
      "id": "-transformation-not-information",
      "style": {
        "position": "relative"
      }
    }}>{`✨ Transformation, not information`}<a parentName="h2" {...{
        "href": "#-transformation-not-information",
        "aria-label": " transformation not information permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Kernel is not about overwhelming you with information. It is about slowly building, through repeated interactions with peers, the patterns (both internal and external) required for a better web. These patterns include humble decisiveness, careful insight, compassionate critique, rational reciprocity and many other complementary opposites which take into account the full spectrum of human awareness. `}</p>
    <p>{`Take your time to go through each module. Stop what you're doing, breathe, set aside an hour to focus, and return again tomorrow or next week when you have enough space to do the same. For the first time in history, the tools we use to write history are fundamentally shared and rooted in consensus. This is a time to pause, reflect and turn attention to the kinds of incentives which will cultivate our ability to live together, each and every one of us.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      